const header = document.querySelector(".header");

window.addEventListener(
  "scroll",
  () => {
    if (document.documentElement.scrollTop > 50) {
      header.classList.add("header--active");
    } else {
      header.classList.remove("header--active");
    }
  },
  { passive: true }
);

const hamburger = document.querySelector(".header__hamburger");
const menu = document.querySelector(".header__options");
const menuElements = document.querySelectorAll(".header__options li");
const lines = document.querySelectorAll(".line");
hamburger.addEventListener("click", () => {
  changeHamburger();
  menu.classList.toggle("header__options--active");
});

menuElements.forEach((el) => {
  el.addEventListener("click", () => {
    menu.classList.remove("header__options--active");
    changeHamburger();
  });
});

function changeHamburger() {
  lines.forEach((el) => {
    el.classList.toggle(`${el.id}--active`);
  });
}


// //Form Validation
// const formName = docuement.querySelector('#name');
// const formEmail = docuement.querySelector('#email');
// const formMessage = docuement.querySelector('#message');
// const form = docuement.querySelector('#form');

